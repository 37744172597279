@import '~react-perfect-scrollbar/dist/css/styles.css';
@import './prismjs';


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #f4f6f8;
    height: 100%;
}

a {
    text-decoration: none;
}

#root {
    height: 100%;
}

:not(pre)>code {
    font-display: auto;
    background-color: #eeeeee;
    padding: 2px 4px;
    direction: ltr;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    font-size: 14px;
}

.previewImage {
    width: 100%;
    height: 100%;
}

.previewImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}