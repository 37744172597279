.imageEditButton {
    position: absolute;
    right: 10px;
    background: white;
    border: none;
    border-radius: 100px;
    width: 35px;
    height: 35px;
    top: 10px;
    cursor: pointer;
}


.setting_section {
    border-top: solid 1px gray;
    border-bottom: solid 1px gray;
    padding: 20px 0px;

    .setting_section_header {
        font-size: 21px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding-bottom: 12px;
        font-weight: bold;
    }
}

.dateCard {
    background: #3f51b5;
    margin: 3px;
    padding: 7px;
    color: white;
    cursor: pointer;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

.conductorCard .image {
    width: 100px;
    aspect-ratio: 4 / 3;
    margin-right: 10px;
}

.conductorCard .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}